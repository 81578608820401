var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, activityDateStart: null, activityDateEnd: null, isExceededLimit: false });
var activitiesServiceState = createSlice({
    name: APPLICATIONS_SERVICES_REDUCER_NAMESPACES.activities,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, setPeriodActivityById: function (state, action) {
            var activity = state.data.content.find(function (_a) {
                var id = _a.id;
                return id === action.payload;
            });
            if (activity) {
                state.activityDateStart = activity.startsAt;
                state.activityDateEnd = activity.endsAt;
            }
        }, setExceededFlag: function (state, action) {
            state.isExceededLimit = action.payload;
        }, resetPeriod: function (state) {
            state.isExceededLimit = false;
            state.activityDateEnd = null;
            state.activityDateStart = null;
        }, resetExceededFlag: function (state) {
            state.isExceededLimit = false;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = activitiesServiceState.actions;
export default activitiesServiceState.reducer;

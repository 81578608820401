var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import React from 'react';
import { CompanyEnabledFeatureKeyType, RoleType } from 'common/types/server-api-dtos';
import { ApplicationsIcon, CartForMenuIcon, HomeIcon, HowIcon, PeopleIcon, ProfileIcon, TeacherIcon, RatingsIcon, AwardsIcon, } from '../icons';
export var getUserRoutes = function () { return [
    {
        path: '/',
        index: true,
        icon: HomeIcon,
        title: t('common.routes.home'),
        isAdaptivePage: true,
        isShownInFooter: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/home'); }),
    },
    {
        path: 'shop',
        icon: CartForMenuIcon,
        title: t('common.routes.shop'),
        isAdaptivePage: true,
        isShownInFooter: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/place-market'); }),
    },
    {
        path: 'income-teal',
        icon: HowIcon,
        title: t('common.routes.incomeTeal'),
        isAdaptivePage: true,
        isShownInFooter: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/income-teal'); }),
    },
    {
        path: 'profile',
        icon: ProfileIcon,
        title: t('common.routes.profile'),
        isAdaptivePage: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/profile/profile-page'); }),
    },
    {
        path: 'settings-profile',
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        title: t('common.routes.settingsProfile'),
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/profile/settings-profile-page'); }),
    },
    {
        path: 'address-book',
        icon: PeopleIcon,
        isAdaptivePage: true,
        title: t('common.routes.addressBook'),
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/address-book/address-book-page'); }),
    },
    {
        path: 'address-book/:userId',
        title: t('common.routes.addressBook'),
        owner: RoleType.Basic,
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/user-pages/address-book/employee-info-page'); }),
    },
    {
        path: 'take-survey/:surveyId',
        title: t('common.routes.surveys'),
        owner: RoleType.Basic,
        isNotIncludeInMenu: true,
        withoutMobileMenu: true,
        isAdaptivePage: true,
        component: React.lazy(function () { return import('pages/user-pages/take-survey'); }),
    },
    {
        path: 'my-development',
        icon: TeacherIcon,
        title: t('common.routes.developmentBlock'),
        owner: RoleType.Basic,
        isAdaptivePage: true,
        featureToggleKey: CompanyEnabledFeatureKeyType.MyDevelopment,
        component: React.lazy(function () { return import('pages/user-pages/development-block/my-development-page'); }),
    },
    {
        path: 'my-employees-development',
        title: t('common.routes.developmentBlock'),
        owner: [RoleType.Supervisor, RoleType.AdminSupervisor],
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/user-pages/development-block/my-employees-page'); }),
    },
    {
        path: 'awards',
        icon: AwardsIcon,
        title: t('common.routes.awards'),
        owner: RoleType.Basic,
        isAdaptivePage: true,
        featureToggleKey: CompanyEnabledFeatureKeyType.Achievements,
        component: React.lazy(function () { return import('pages/user-pages/awards/awards-page'); }),
    },
    {
        path: 'award/:awardId',
        icon: AwardsIcon,
        title: t('common.routes.awards'),
        owner: RoleType.Basic,
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        featureToggleKey: CompanyEnabledFeatureKeyType.Achievements,
        component: React.lazy(function () { return import('pages/user-pages/awards/detail-award-page'); }),
    },
    {
        path: 'ratings',
        icon: RatingsIcon,
        isAdaptivePage: true,
        title: t('common.routes.rating'),
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/ratings'); }),
    },
    {
        path: 'create-one-to-one',
        title: t('common.routes.createNewOneToOne'),
        isNotIncludeInMenu: true,
        owner: [RoleType.Supervisor, RoleType.AdminSupervisor],
        component: React.lazy(function () { return import('pages/user-pages/development-block/create-one-to-one-page'); }),
    },
    {
        path: 'edit-one-to-one/:id',
        title: t('common.routes.updateOneToOne'),
        isNotIncludeInMenu: true,
        owner: [RoleType.Supervisor, RoleType.AdminSupervisor],
        component: React.lazy(function () { return import('pages/user-pages/development-block/edit-one-to-one-page'); }),
    },
    {
        path: 'post/:postId',
        title: t('common.routes.post'),
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/detail-post-pages/detail-post'); }),
    },
    {
        path: 'award-post/:postId',
        title: t('common.routes.award'),
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/detail-post-pages/detail-post'); }),
    },
    {
        path: 'leader-post/:postId',
        title: t('common.routes.leaders'),
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/detail-post-pages/detail-leader-page'); }),
    },
    {
        path: 'recognition-post/:recognitionPostId',
        title: t('common.routes.gratitudePost'),
        isAdaptivePage: true,
        withoutMobileMenu: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/detail-post-pages/detail-gratitude-post'); }),
    },
    {
        path: 'chat/:chatId',
        title: t('common.routes.chat'),
        isNotIncludeInMenu: true,
        withoutMobileMenu: true,
        isAdaptivePage: true,
        owner: RoleType.Basic,
        component: React.lazy(function () { return import('pages/user-pages/chat'); }),
    },
]; };
export var getSupervisorRoutes = function () { return [
    {
        path: 'supervisor-applications',
        icon: ApplicationsIcon,
        title: t('common.routes.supervisorApplications'),
        owner: RoleType.Supervisor,
        component: React.lazy(function () { return import('pages/supervisor-pages/applications'); }),
    },
]; };
export var getManagerRoutes = function () { return [
    {
        path: 'employees',
        title: t('common.routes.employees'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/employees/empoyees-page'); }),
    },
    {
        path: 'edit/employee/:userId',
        title: t('common.routes.editEmployee'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/employees/edit-employee-page'); }),
    },
    {
        path: 'applications',
        title: t('common.routes.applications'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/applications'); }),
    },
    {
        path: 'settings',
        title: t('common.routes.settings'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/settings/settings-page'); }),
    },
    {
        path: 'create-emission',
        title: t('common.routes.createEmission'),
        isNotIncludeInMenu: true,
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/settings/create-emission-page'); }),
    },
    {
        path: 'edit-emission/:emissionId',
        title: t('common.routes.editEmission'),
        isNotIncludeInMenu: true,
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/settings/edit-emission-page'); }),
    },
    {
        path: 'transactions',
        title: t('common.routes.transactions'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/transactions'); }),
    },
    {
        path: 'orders',
        title: t('common.routes.orders'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/orders'); }),
    },
    {
        path: 'activities',
        title: t('common.routes.activities'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/activities'); }),
    },
    {
        path: 'products',
        title: t('common.routes.products'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/products/products-page'); }),
    },
    {
        path: 'create-product',
        title: t('common.routes.createProduct'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/products/create-product-page'); }),
    },
    {
        path: 'create-award',
        title: t('common.routes.createAward'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/awards/create-award-page'); }),
    },
    {
        path: 'edit-award/:awardId',
        title: t('common.routes.editAward'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/awards/edit-award-page'); }),
    },
    {
        path: 'edit-product/:productId',
        title: t('common.routes.editProduct'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/products/edit-product-page'); }),
    },
    {
        path: 'surveys',
        title: t('common.routes.surveys'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/surveys-page'); }),
    },
    {
        path: 'select-survey-type',
        title: t('common.routes.surveys'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/select-survey-type-page'); }),
    },
    {
        path: 'create-survey',
        title: t('common.routes.createSurvey'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/create-survey-page'); }),
    },
    {
        path: 'edit-survey/:surveyId',
        title: t('common.routes.editSurvey'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/edit-survey-page'); }),
    },
    {
        path: 'survey-results/:surveyId',
        title: t('common.routes.surveyResults'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/survey-results/survey-results-page'); }),
    },
    {
        path: 'enps-survey-results/:surveyId',
        title: t('common.routes.surveyResults'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/surveys/survey-results/enps-survey-results-page'); }),
    },
    {
        path: 'support-requests',
        title: t('common.routes.supportRequests'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/support-requests/support-requests-page'); }),
    },
    {
        path: 'support-request/:requestId',
        title: t('common.routes.supportRequests'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/support-requests/support-request-processing-page'); }),
    },
    {
        path: 'statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/navigation-statistics-dashboard-page'); }),
    },
    {
        path: 'team-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/team-statistics-page'); }),
    },
    {
        path: 'corporate-currency-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/corporate-currency-statistics-page'); }),
    },
    {
        path: 'activities-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/modular-analytics-activities-page'); }),
    },
    {
        path: 'surveys-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/surveys-statistic-page'); }),
    },
    {
        path: 'rewards-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/modular-analytics-rewards-page'); }),
    },
    {
        path: 'gratitudes-statistics',
        title: t('common.routes.statistics'),
        owner: RoleType.Manager,
        isNotIncludeInMenu: true,
        component: React.lazy(function () { return import('pages/manager-pages/statistics/modular-analytics-gratitudes-page'); }),
    },
    {
        path: 'post/create',
        title: t('common.routes.createPost'),
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/posts/create-post'); }),
    },
    {
        path: 'post/edit/:postId',
        title: t('common.routes.editPost'),
        isAdaptivePage: true,
        isNotIncludeInMenu: true,
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/manager-pages/posts/edit-post-page'); }),
    },
]; };
export var getAdminRoutes = function () { return [
    {
        path: 'users',
        title: t('common.routes.users'),
        owner: RoleType.Admin,
        component: React.lazy(function () { return import('pages/admin-pages/users'); }),
    },
]; };
export var getBasicRoutes = function () { return __spreadArray(__spreadArray(__spreadArray([], getSupervisorRoutes(), true), getManagerRoutes(), true), getAdminRoutes(), true); };
export var getRegistrationRoutes = function () { return [
    {
        path: 'registration',
        title: t('common.routes.registration'),
        owner: null,
        isAdaptivePage: true,
        component: React.lazy(function () { return import('pages/auth/registration-form'); }),
    },
]; };
export var getUserSelfRegistrationRoutes = function () { return [
    {
        path: 'registration/:companyId',
        isAdaptivePage: true,
        title: t('common.routes.fpkRegistration'),
        owner: null,
        component: React.lazy(function () { return import('pages/auth/user-self-registration-form'); }),
    },
    {
        path: 'hr-club-registration/:companyId',
        isAdaptivePage: true,
        title: t('common.routes.hrClubRegistration'),
        owner: null,
        component: React.lazy(function () { return import('pages/auth/hr-club-registration-form'); }),
    },
]; };
export var getGuestRoutes = function () { return [
    {
        path: 'take-survey/link-access/:surveyId',
        title: t('common.routes.surveys'),
        owner: null,
        isAdaptivePage: true,
        component: React.lazy(function () { return import('pages/guest-pages/take-survey-guest'); }),
    },
    {
        path: 'preview-survey',
        title: t('common.routes.previewSurvey'),
        owner: RoleType.Manager,
        component: React.lazy(function () { return import('pages/guest-pages/preview-survey-page'); }),
    },
    {
        path: 'docs',
        title: t('common.routes.docs'),
        isAdaptivePage: true,
        owner: null,
        component: React.lazy(function () { return import('pages/guest-pages/docs-page'); }),
    },
]; };

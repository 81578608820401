var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSidebarActions } from '../../service/hooks';
import { sidebarServiceIsOpenMenuSelector } from '../../service/selectors';
import { Box } from 'ui-kit/box';
import { Navigation } from '../navigation';
import { Header, MobileMenuOverlay, Footer, RoutesObserver } from './modules';
import styles from './mobile-menu.module.scss';
export var MobileMenu = function () {
    var setIsOpenMenu = useSidebarActions().setIsOpenMenu;
    var isOpen = useSelector(sidebarServiceIsOpenMenuSelector);
    var closeModalHandler = useCallback(function () {
        setIsOpenMenu(false);
    }, [setIsOpenMenu]);
    return (_jsxs(_Fragment, { children: [_jsxs(MobileMenuOverlay, __assign({ pt: 12, display: "flex", direction: "column", isOpen: isOpen, onClose: closeModalHandler }, { children: [_jsx(Box, __assign({ className: styles.headerWrapper, px: 8, pb: 4, display: "block" }, { children: _jsx(Header, {}) })), _jsx(Navigation, { isNotDesktop: true, className: styles.navigationContainer }), _jsx(Box, __assign({ className: styles.footerWrapper, px: 16, py: 8 }, { children: _jsx(Footer, { className: styles.footerAction }) }))] })), _jsx(RoutesObserver, { onClose: closeModalHandler })] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CompanyEnabledFeatureKeyType, RoleType } from 'common/types/server-api-dtos';
import { useProfileActions } from 'common/services/profile-service/hooks';
import { useConversionActions } from 'common/services/conversion-service/hooks';
import { useCustomizationsActions } from 'common/services/customizations-service/hooks';
import { useSupportRequestTypesActions } from 'common/services/support-request-types-service/hooks';
import { useCompanyAdditionalInfoActions } from 'common/services/company-additional-info-service/hooks';
import { useShowThanksBalanceActions } from 'common/services/show-thanks-balance-service/hooks';
import { profileServiceInitializedSelector } from 'common/services/profile-service/selectors';
import { customizationsServiceInitializedSelector } from 'common/services/customizations-service/selectors';
import { GuestLayout, MainLayout, RegistrationLayout, UserSelfRegistrationLayout } from 'common/layouts';
import { RequiredAuth } from 'common/components/required-auth';
import { RequiredUnauthorized } from 'common/components/required-unauthorized';
import { EventsListener } from 'common/components/events-listener';
import { ScrollUp } from 'common/components/scroll-up';
import { HashUrlListener } from 'common/components/hash-url-listener';
import { Toast } from 'ui-kit/toast';
import { FallbackComponent } from 'common/components/fallback-component';
import { ThemeObserver } from 'common/components/theme-observer/theme-observer';
import { InitializationAdditionalScripts } from 'common/components/initialization-additional-scripts';
import { Wallpaper } from 'common/components/wallpaper';
import { NoMatch } from 'pages/error-pages/no-match';
import { userService } from 'common/utils/user-service';
import { getPartRoutes } from 'common/helpers/get-part-routes';
import i18next from './i18n-service/i18n';
export var Initialization = function (_a) {
    var children = _a.children;
    var _b = useProfileActions(), fetchProfileAction = _b.fetchProfileAction, resetProfileStateAction = _b.resetState;
    var _c = useCompanyAdditionalInfoActions(), fetchAdditionalInfoAction = _c.fetchAdditionalInfoAction, resetCompanyAdditionalInfoStateAction = _c.resetState;
    var _d = useConversionActions(), fetchConversionAction = _d.fetchConversionAction, resetConversionState = _d.resetState;
    var _e = useCustomizationsActions(), fetchCustomizationsAction = _e.fetchCustomizationsAction, resetCustomizationsAction = _e.resetCustomizationsAction;
    var _f = useSupportRequestTypesActions(), fetchSupportRequestTypesAction = _f.fetchSupportRequestTypesAction, resetSupportRequestTypesStateAction = _f.resetState;
    var _g = useShowThanksBalanceActions(), checkShowThanksBalanceAction = _g.checkShowThanksBalanceAction, resetShowThanksBalanceStateAction = _g.resetState;
    var isAuth = userService.isLoggedIn();
    var isProfileInitialized = useSelector(profileServiceInitializedSelector);
    var isCustomizationsInialized = useSelector(customizationsServiceInitializedSelector);
    var showWallpaper = useMemo(function () { return isAuth && (!isProfileInitialized || !isCustomizationsInialized); }, [isAuth, isProfileInitialized, isCustomizationsInialized]);
    useEffect(function () {
        var currentLanguage = i18next.language;
        document.documentElement.setAttribute('lang', currentLanguage);
        document.documentElement.setAttribute('dir', currentLanguage.includes('ar') ? 'rtl' : 'ltr');
    }, []);
    useEffect(function () {
        if (isAuth) {
            fetchProfileAction();
            fetchConversionAction();
            fetchCustomizationsAction(function (data) {
                if (data.companyEnabledFeatureKeys.includes(CompanyEnabledFeatureKeyType.AllowEmployeesToTransferCurrency))
                    checkShowThanksBalanceAction();
            });
            fetchSupportRequestTypesAction();
        }
        else {
            var handler_1 = function () {
                resetCustomizationsAction();
            };
            window.addEventListener('unload', handler_1);
            return function () {
                resetConversionState();
                resetProfileStateAction();
                resetShowThanksBalanceStateAction();
                resetSupportRequestTypesStateAction();
                resetCompanyAdditionalInfoStateAction();
                window.removeEventListener('unload', handler_1);
            };
        }
    }, [
        fetchProfileAction,
        fetchConversionAction,
        fetchCustomizationsAction,
        checkShowThanksBalanceAction,
        fetchSupportRequestTypesAction,
        resetConversionState,
        resetProfileStateAction,
        resetCustomizationsAction,
        resetShowThanksBalanceStateAction,
        resetSupportRequestTypesStateAction,
        resetCompanyAdditionalInfoStateAction,
        isAuth,
    ]);
    useEffect(function () {
        if (isAuth && userService.hasRole([RoleType.Manager, RoleType.Admin, RoleType.RootAdmin])) {
            fetchAdditionalInfoAction();
        }
    }, [fetchAdditionalInfoAction, isAuth]);
    return showWallpaper ? _jsx(Wallpaper, {}) : _jsx(_Fragment, { children: children });
};
var AppContent = function () {
    var routes = useMemo(function () { return ({
        userRoutes: getPartRoutes('userRoutes'),
        basic: getPartRoutes('basic'),
        registration: getPartRoutes('registration'),
        userSelfRegistration: getPartRoutes('userSelfRegistration'),
        guestRoutes: getPartRoutes('guest'),
    }); }, []);
    return (_jsxs(Routes, { children: [_jsxs(Route, __assign({ element: _jsx(RequiredAuth, {}) }, { children: [_jsxs(Route, __assign({ path: "/", element: _jsx(MainLayout, {}) }, { children: [routes.userRoutes.adaptivePages, routes.basic.adaptivePages] })), _jsx(Route, __assign({ path: "/", element: _jsx(MainLayout, { withoutMobileMenu: true }) }, { children: routes.userRoutes.withoutMobileMenuPages })), _jsxs(Route, __assign({ path: "/", element: _jsx(MainLayout, { withRiseze: true }) }, { children: [routes.userRoutes.notAdaptivePages, routes.basic.notAdaptivePages] }))] })), _jsxs(Route, __assign({ element: _jsx(RequiredUnauthorized, {}) }, { children: [_jsx(Route, __assign({ path: "/", element: _jsx(RegistrationLayout, {}) }, { children: routes.registration.adaptivePages })), _jsx(Route, __assign({ path: "/", element: _jsx(UserSelfRegistrationLayout, {}) }, { children: routes.userSelfRegistration.adaptivePages }))] })), _jsx(Route, __assign({ path: "/", element: _jsx(GuestLayout, {}) }, { children: routes.guestRoutes.adaptivePages })), _jsx(Route, __assign({ path: "/", element: _jsx(GuestLayout, { withRiseze: true }) }, { children: routes.guestRoutes.notAdaptivePages })), _jsx(Route, { path: "*", element: _jsx(NoMatch, {}) })] }));
};
var App = function () { return (_jsxs(_Fragment, { children: [_jsx(Initialization, { children: _jsxs(ErrorBoundary, __assign({ FallbackComponent: FallbackComponent }, { children: [_jsx(AppContent, {}), _jsx(ScrollUp, {}), _jsx(HashUrlListener, {}), _jsx(InitializationAdditionalScripts, {})] })) }), _jsx(Toast, {}), _jsx(ThemeObserver, {}), _jsx(EventsListener, {})] })); };
export default withTranslation()(App);

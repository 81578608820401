var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui-kit/button';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { ArrowLeftSimple } from 'common/icons';
import styles from './error-page.module.scss';
// TODO: Убрать 'mainLayoutOld' после появления адаптива для страниц с ошибками
export var ErrorPage = function (_a) {
    var code = _a.code, title = _a.title, decription = _a.decription, comment = _a.comment, hideGoBackButton = _a.hideGoBackButton;
    var navigate = useNavigate();
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "".concat(styles.wrapper, " mainLayoutOld") }, { children: [_jsx("div", __assign({ className: styles.content }, { children: _jsxs("div", __assign({ className: styles.row }, { children: [_jsx("div", __assign({ className: styles.col }, { children: _jsx("div", __assign({ className: styles.number }, { children: code })) })), _jsxs("div", __assign({ className: styles.col }, { children: [_jsx(Typography, __assign({ className: styles.title, variant: "h1", component: "h1" }, { children: title })), _jsx(Typography, __assign({ variant: "body-m", className: styles.text, component: "div" }, { children: decription })), _jsx(Typography, __assign({ variant: "body-m", className: styles.text, component: "div" }, { children: comment })), !hideGoBackButton && (_jsx(Button, __assign({ onClick: function () { return navigate(-1); }, leftIcon: _jsx(Icon, { color: "contrast", component: ArrowLeftSimple }), className: styles.button }, { children: t('errorPageCommon.buttonText') })))] }))] })) })), _jsx("div", { className: styles.contentBottom })] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { profileServiceTealBalanceSelector, profileServiceThanksBalanceSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { FormattedNumber } from 'ui-kit/formatted-number';
import { CurrencyIcon } from 'common/components/currency-icon';
import { CurrentUserAvatar } from 'common/components/current-user-avatar';
import { ThanksCurrencyIcon } from 'common/components/thanks-currency-icon';
import { CheckRenderThanksBalance } from 'common/components/check-render-thanks-balance';
import styles from './user-info.module.scss';
export var UserInfo = memo(function (_a) {
    var showAll = _a.showAll, rest = __rest(_a, ["showAll"]);
    var tealBalance = useSelector(profileServiceTealBalanceSelector);
    var thanksBalance = useSelector(profileServiceThanksBalanceSelector);
    return (_jsx(Link, __assign({ to: "/profile" }, { children: _jsxs(Box, __assign({ className: styles.userInfo, px: 8, py: 6, alignItems: "center" }, rest, { children: [_jsx(CurrentUserAvatar, { mr: 4 }), showAll && (_jsxs(Box, __assign({ display: "flex", direction: "column" }, { children: [_jsx(CheckRenderThanksBalance, { children: _jsx(FormattedNumber, { value: thanksBalance, variant: "h6", component: "span", color: "primary", prefix: _jsx(ThanksCurrencyIcon, { width: 1 }) }) }), _jsx(FormattedNumber, { value: tealBalance, variant: "h6", component: "span", color: "primary", prefix: _jsx(CurrencyIcon, { width: 1 }) })] })))] })) })));
});

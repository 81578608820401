var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { profileServiceTealBalanceSelector, profileServiceThanksBalanceSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { Divider } from 'ui-kit/divider';
import { FormattedNumber } from 'ui-kit/formatted-number';
import { CurrentUserAvatar } from 'common/components/current-user-avatar';
import { Logo } from 'common/components/logo';
import { CurrencyIcon } from 'common/components/currency-icon';
import { ThanksCurrencyIcon } from 'common/components/thanks-currency-icon';
import { CheckRenderThanksBalance } from 'common/components/check-render-thanks-balance';
import styles from './mobile-header.module.scss';
var UserInfo = function (_a) {
    var pl = _a.pl;
    var tealBalance = useSelector(profileServiceTealBalanceSelector);
    var thankBalance = useSelector(profileServiceThanksBalanceSelector);
    return (_jsxs(Box, __assign({ pl: pl, alignItems: "center", wrap: "nowrap" }, { children: [_jsxs(CheckRenderThanksBalance, { children: [_jsx(FormattedNumber, { value: thankBalance, variant: "h6", component: "span", color: "primary", postfix: _jsx(ThanksCurrencyIcon, {}) }), _jsx(Divider, { className: styles.divider, orientation: "vertical", mr: 4, ml: 4 })] }), _jsx(FormattedNumber, { value: tealBalance, variant: "h6", component: "span", color: "primary", postfix: _jsx(CurrencyIcon, {}) }), _jsx(CurrentUserAvatar, { ml: 4 })] })));
};
export var MobileHeader = function () { return (_jsxs(Box, __assign({ className: styles.header, display: "flex", wrap: "nowrap", alignItems: "center", justify: "space-between", component: "header" }, { children: [_jsx(Link, __assign({ className: styles.link, to: "/" }, { children: _jsx(Logo, { size: "small" }) })), _jsx(Link, __assign({ className: styles.link, to: "/profile" }, { children: _jsx(UserInfo, { pl: 8 }) }))] }))); };

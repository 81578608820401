var _a;
import { combineReducers } from 'redux';
import applicationsFilterServiceReducer from './filter-service/reducer';
import applicationsServiceReducer from './applications-service/reducer';
import employeesServiceReducer from './employees-service/reducer';
import activitiesServiceReducer from './activities-service/reducer';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var applicationServicesRootReducer = combineReducers((_a = {},
    _a[APPLICATIONS_SERVICES_REDUCER_NAMESPACES.filter] = applicationsFilterServiceReducer,
    _a[APPLICATIONS_SERVICES_REDUCER_NAMESPACES.applicationsList] = applicationsServiceReducer,
    _a[APPLICATIONS_SERVICES_REDUCER_NAMESPACES.employees] = employeesServiceReducer,
    _a[APPLICATIONS_SERVICES_REDUCER_NAMESPACES.activities] = activitiesServiceReducer,
    _a));

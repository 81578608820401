import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CompanyEnabledFeatureKeyType, RoleType } from 'common/types/server-api-dtos';
import { EE, EventsBus } from '../../utils/events-bus';
import { useToastActions } from 'common/services/toast-service/hooks';
import { useProfileActions } from 'common/services/profile-service/hooks';
import { useCustomizationsActions } from 'common/services/customizations-service/hooks';
import { useCompanyAdditionalInfoActions } from 'common/services/company-additional-info-service/hooks';
import { useSupportRequestTypesActions } from 'common/services/support-request-types-service/hooks';
import { useShowThanksBalanceActions } from 'common/services/show-thanks-balance-service/hooks';
import { customizationsServicecoIsEnabledFeatureKeySelector } from 'common/services/customizations-service/selectors';
import { userService } from 'common/utils/user-service';
import { checkIsOnline } from 'common/helpers/check-is-online';
import { ONE_MINUTE } from 'common/constants/common-time';
var INTERVAL = ONE_MINUTE * 10;
export var EventsListener = function () {
    var t = useTranslation().t;
    var fetchProfileAction = useProfileActions().fetchProfileAction;
    var fetchCustomizationsAction = useCustomizationsActions().fetchCustomizationsAction;
    var checkShowThanksBalanceAction = useShowThanksBalanceActions().checkShowThanksBalanceAction;
    var fetchAdditionalInfoAction = useCompanyAdditionalInfoActions().fetchAdditionalInfoAction;
    var fetchSupportRequestTypesAction = useSupportRequestTypesActions().fetchSupportRequestTypesAction;
    var _a = useToastActions(), addErrorToast = _a.addErrorToast, addToast = _a.addToast;
    var isAllowEmployeesToTransferCurrency = useSelector(customizationsServicecoIsEnabledFeatureKeySelector(CompanyEnabledFeatureKeyType.AllowEmployeesToTransferCurrency));
    var isAllowEmployeesToTransferCurrencyRef = useRef(isAllowEmployeesToTransferCurrency);
    useEffect(function () {
        isAllowEmployeesToTransferCurrencyRef.current = isAllowEmployeesToTransferCurrency;
    }, [isAllowEmployeesToTransferCurrency]);
    useEffect(function () {
        var updateCustomizationsCb = function () {
            fetchCustomizationsAction(function (data) {
                if (data.companyEnabledFeatureKeys.includes(CompanyEnabledFeatureKeyType.AllowEmployeesToTransferCurrency))
                    checkShowThanksBalanceAction();
            });
        };
        var intervalId = Number(setInterval(function () {
            if (!userService.isLoggedIn() || !checkIsOnline())
                return;
            fetchProfileAction();
            fetchSupportRequestTypesAction();
            if (isAllowEmployeesToTransferCurrencyRef.current)
                checkShowThanksBalanceAction();
            if (userService.hasRole([RoleType.Manager, RoleType.Admin, RoleType.RootAdmin]))
                fetchAdditionalInfoAction();
        }, INTERVAL));
        EE.on(EventsBus.editProfile, fetchProfileAction);
        EE.on(EventsBus.updateCustomizations, updateCustomizationsCb);
        return function () {
            clearInterval(intervalId);
            EE.off(EventsBus.editProfile, fetchProfileAction);
            EE.off(EventsBus.updateCustomizations, updateCustomizationsCb);
        };
    }, [
        fetchCustomizationsAction,
        fetchProfileAction,
        fetchAdditionalInfoAction,
        fetchSupportRequestTypesAction,
        checkShowThanksBalanceAction,
    ]);
    useEffect(function () {
        var showToastCb = function (_a) {
            var title = _a.title, message = _a.message;
            addErrorToast({ title: title || t('eventsListener.errorToast.titleDef'), message: message });
        };
        var logoutCb = function () {
            addToast({
                title: t('eventsListener.errorToast.unauthorizeTitle'),
                message: t('eventsListener.errorToast.unauthorizeMessage'),
            });
        };
        EE.on(EventsBus.showMessage, showToastCb);
        EE.on(EventsBus.logout, logoutCb);
        return function () {
            EE.off(EventsBus.showMessage, showToastCb);
            EE.off(EventsBus.logout, logoutCb);
        };
    }, [addErrorToast, t]);
    return null;
};

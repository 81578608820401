var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { RECIVE_TEAL_FORM_SERVICE_REDUCER_NAMESPACES } from './constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, activityId: null, activityDataById: null, isExceededLimit: false, activityDateStart: null, activityDateEnd: null, isShowForm: false });
var reciveTealFormServiceState = createSlice({
    name: RECIVE_TEAL_FORM_SERVICE_REDUCER_NAMESPACES.reciveTealForm,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, choiceActivityById: function (state, action) {
            var activity = state.data.content.find(function (_a) {
                var id = _a.id;
                return id === action.payload;
            });
            if (activity) {
                state.activityDateStart = activity.startsAt || null;
                state.activityDateEnd = activity.endsAt || null;
                state.activityDataById = activity;
            }
        }, resetPeriod: function (state) {
            state.activityDateEnd = null;
            state.activityDateStart = null;
        }, setExceededFlag: function (state, action) {
            state.isExceededLimit = action.payload;
        }, resetExceededFlag: function (state) {
            state.isExceededLimit = false;
        }, openForm: function (state, action) {
            state.isShowForm = true;
            if (action.payload) {
                state.activityId = action.payload;
            }
        }, closeForm: function (state) {
            state.activityId = null;
            state.isShowForm = false;
            state.activityDataById = null;
            state.activityDateEnd = null;
            state.activityDateStart = null;
            state.isExceededLimit = false;
        } }, baseReducers),
});
export var actions = reciveTealFormServiceState.actions;
export default reciveTealFormServiceState.reducer;
